// Instagram.js
import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Image from 'gatsby-image';

const InstagramFeedGatsby = () => (
  <StaticQuery
    query={graphql`
      query InstagramPosts {
        allInstaNode(sort: {order: DESC, fields: timestamp}, limit: 12) {
          edges {
            node {
              id
              likes
              mediaType
              preview
              original
              timestamp
              caption
              localFile {
                childImageSharp {
                  fluid(maxHeight: 500, maxWidth: 500 quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
              thumbnails {
                src
                config_width
                config_height
              }
              dimensions {
                height
                width
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <div>
        <table style={{width: '100%'}}>
          <tbody>
          <tr>
        {
          data.allInstaNode.edges.map((item, i) => (
            item.node.localFile ? (
              <td key={i}>
                <a href={"https://instagram.com/p/" + item.node.id}>
                <Image
                  fluid={item.node.localFile.childImageSharp.fluid}
                />
                </a>
              </td>

            ) : (<div></div>)
          ))
        }
          </tr>
          </tbody>
        </table>
      </div>
    )}
  />
);

export default InstagramFeedGatsby;
