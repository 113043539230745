import React from 'react'
import './Footer.css'
import InstagramFeedGatsby from './InstagramFeedGatsby';

export default () => (
  <div>
    <h2 className="taCenter">
      Folge uns{' '}
      <a href="https://www.instagram.com/beggendorfer_hof_offiziell/">@beggendorfer_hof_offiziell</a>
    </h2>
    <br />
    <div id="instagram-feed1">
    <InstagramFeedGatsby/>
    </div>
    
    <footer className="footer">
      <div className="container taCenter">
        <span>
          © Copyright {new Date().getFullYear()} All rights reserved. Template Crafted by{' '}
          <a href="https://thriveweb.com.au/">Thrive</a>.
        </span>
      </div>
    </footer>
  </div>
)
